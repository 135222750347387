import { MainScreen } from '../screens/main-screen';
import { CryptoPaymentContextProvider } from '../context/crypto-payment-context';

export const CryptoPaymentForm = () => {
  return (
    <CryptoPaymentContextProvider>
      <MainScreen />
    </CryptoPaymentContextProvider>
  );
};
