import { FC } from 'react';
import { Box, Flex, HStack, Text, TextProps, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { CopyComponent } from '@payler/payment-page-ui-shared';

export const CopyTextBlock: FC<{
  value: string;
  label: string;
  labelProps?: TextProps;
  valueProps?: TextProps;
  canCopy?: boolean;
}> = ({ label, value, labelProps, valueProps, canCopy = true }) => {
  return (
    <HStack
      w="full"
      spacing={0}
      p={2}
      bg="primary.25"
      justifyContent="space-between"
    >
      <VStack spacing={0} w="90%" alignItems="flex-start">
        <Text
          w="full"
          textStyle={TextStyles.Subtitle14Regular}
          color="primary.350"
          {...labelProps}
        >
          {label}
        </Text>
        <Text
          w="full"
          textStyle={TextStyles.BodyText16Medium}
          color="primary.500"
          fontWeight={500}
          {...valueProps}
        >
          {value}
        </Text>
      </VStack>
      {canCopy && (
        <Flex>
          <CopyComponent value={value} data-testid="copy-text-btn" />
        </Flex>
      )}
    </HStack>
  );
};
