import { ScreenWrapper } from '../components/wrappers/screen-wrapper';
import {
  CopyTextBlock,
  DetailsItem,
  DividedFooter,
  FullPageLoader,
  ScreenBody,
  ScreenHeader,
  useLayout,
} from '@payler/payment-page-ui-shared';
import {
  Box,
  Image,
  VStack,
  Text,
  Button,
  HStack,
  Divider,
  Flex,
} from '@chakra-ui/react';
import { Alert } from '@payler/payment-page-ui-shared';
import { FC, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TextStyles } from '@payler/ui-theme';
import {
  useDepositFromSelectedToken,
  useDepositInfo,
  useMarkDepositPaidMutation,
} from '../hooks/deposit';
import { useCryptoPaymentContext } from '../context/crypto-payment-context';
import { useAvailableTokens } from '../hooks/tokens';
import { ThinDivider } from '@payler/ui-components';
import { usePaymentSessionForm } from '../hooks/payment-session';
import dayjs from 'dayjs';

export const DepositInfoScreen = () => {
  const { data, isLoading } = useDepositFromSelectedToken();
  const { data: availableTokens } = useAvailableTokens();
  const { setScreen } = useCryptoPaymentContext();
  const isBackButtonAvailable = (availableTokens?.length ?? 0) > 1;

  const handleClickBack = useCallback(() => {
    setScreen('PaymentSelectionScreen');
  }, [setScreen]);

  if (isLoading && !data) {
    return <FullPageLoader />;
  }

  return (
    <ScreenWrapper onBack={isBackButtonAvailable ? handleClickBack : undefined}>
      <Head />
      <Body />
      <Footer />
    </ScreenWrapper>
  );
};

const Head = () => {
  const [isOpenedDetails, setIsOpenedDetails] = useState(false);
  const { t } = useTranslation();
  const { tokenCode } = useDepositInfo();
  const { isMobile } = useLayout();

  return (
    <Box>
      <ScreenHeader pt={isMobile ? 0 : undefined}>
        <Text as="h1" textStyle={TextStyles.h1}>
          {t('sendDeposit')}
        </Text>
        <Flex
          gap={isMobile ? 2 : 3}
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <Description currency={tokenCode} />
          <Button
            w="max-content"
            variant="secondary"
            onClick={() => setIsOpenedDetails((prev) => !prev)}
            h={6}
          >
            {isOpenedDetails ? t('buttons:hide') : t('buttons:paymentDetails')}
          </Button>
        </Flex>
        {isOpenedDetails && <PaymentDetails />}
      </ScreenHeader>
      <Divider />
    </Box>
  );
};

const Body = () => {
  const { isMobile } = useLayout();
  const { address, minimumDeposit, tokenCode } = useDepositInfo();

  return (
    <ScreenBody>
      <VStack
        w="full"
        alignItems="flex-start"
        spacing={2}
        mt={isMobile ? 2 : 3}
      >
        <DepositQr />
        <DepositInfo address={address.address} />
        {isMobile && (
          <MinimumAmountAlert amount={minimumDeposit} currency={tokenCode} />
        )}
      </VStack>
    </ScreenBody>
  );
};

const Footer = () => {
  const { t } = useTranslation();
  const { isMobile } = useLayout();
  const { markDepositPaid, isLoading } = useMarkDepositPaidMutation();
  const { setScreen } = useCryptoPaymentContext();
  const { data } = useAvailableTokens();

  const isBackButtonAvailable = (data?.length ?? 0) > 1;

  const handleClickMadeDepositButton = () => {
    markDepositPaid();
  };

  const handleClickBack = () => {
    setScreen('PaymentSelectionScreen');
  };

  if (isMobile) {
    return (
      <Box pt={2} pb={2} position="sticky" bottom={0} bg="secondary.500">
        <DividedFooter pb={0}>
          <Button
            w="full"
            onClick={handleClickMadeDepositButton}
            isLoading={isLoading}
            data-testid="confirm-payment-btn"
          >
            {t('buttons:completedPayment')}
          </Button>
        </DividedFooter>
      </Box>
    );
  }

  return (
    <Box pt={3} pb={4} px={4}>
      <HStack w="full" spacing={2}>
        {isBackButtonAvailable && (
          <Button w="full" variant="secondary" onClick={handleClickBack}>
            {t('buttons:back')}
          </Button>
        )}
        <Button
          w="full"
          onClick={handleClickMadeDepositButton}
          isLoading={isLoading}
          data-testid="confirm-payment-btn"
        >
          {t('buttons:completedPayment')}
        </Button>
      </HStack>
    </Box>
  );
};

const Description: FC<{ currency: string }> = ({ currency }) => {
  const { t } = useTranslation('common');
  return (
    <VStack w="full" alignItems="flex-start">
      <Text textStyle={TextStyles.BodyText16Regular} color="primary.350">
        <Trans
          i18nKey="completePayment"
          values={{ currency }}
          components={{
            darker: (
              <Text
                as="span"
                textStyle={TextStyles.BodyText16Medium}
                color="primary.500"
              />
            ),
          }}
        />
      </Text>
    </VStack>
  );
};

const DepositQr = () => {
  const { t } = useTranslation('common');
  const { isDesktop, isMobile } = useLayout();
  const { addressQrCodeUrl, tokenCode, minimumDeposit } = useDepositInfo();

  return (
    <VStack w="full" alignItems="flex-start" spacing={2}>
      <Text
        as="h4"
        w="full"
        textAlign={isMobile ? 'center' : undefined}
        textStyle={TextStyles.h4}
      >
        {t('depositAddress')}
      </Text>
      <HStack w="full" justifyContent="center" spacing={3}>
        <Image src={addressQrCodeUrl} w="128px" h="128px" />
        {isDesktop && (
          <MinimumAmountAlert amount={minimumDeposit} currency={tokenCode} />
        )}
      </HStack>
    </VStack>
  );
};

const DepositInfo: FC<{ address: string }> = ({ address }) => {
  const { t } = useTranslation();
  const { networkDisplayName } = useDepositInfo();
  const {
    data: { paymentDescription },
  } = usePaymentSessionForm();

  return (
    <VStack
      spacing={0}
      divider={<ThinDivider borderColor="primary.25" />}
      w="full"
      border="1px solid"
      borderRadius={1}
      borderColor="primary.100"
    >
      <CopyTextBlock
        value={address}
        label={t('common:depositAddress')}
        valueProps={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      />
      {paymentDescription && (
        <CopyTextBlock
          value={paymentDescription}
          label={t('fields:paymentDescription')}
        />
      )}
      <Box
        w="full"
        p={2}
        display="flex"
        justifyContent="center"
        bg="primary.50"
      >
        <Trans
          i18nKey="common:makeDepositInCurrentNetwork"
          values={{ currentNetwork: networkDisplayName }}
          components={{
            secondary: (
              <Text
                as="span"
                textStyle={TextStyles.BodyText16Regular}
                color="primary.400"
              />
            ),
            darker: (
              <Text
                as="span"
                textStyle={TextStyles.BodyText16Medium}
                color="primary.500"
              />
            ),
          }}
        />
      </Box>
    </VStack>
  );
};

const MinimumAmountAlert: FC<{ amount: number; currency: string }> = ({
  amount,
  currency,
}) => {
  const { t } = useTranslation('common');
  return (
    <Alert
      label={t('important')}
      description={
        <Trans
          i18nKey="minimumAmountAlert"
          values={{ currency, amount }}
          components={{
            darker: (
              <Text
                as="span"
                textStyle={TextStyles.Subtitle14Medium}
                color="primary.500"
              />
            ),
          }}
        />
      }
      bgColor="primary.50"
    />
  );
};

const PaymentDetails = () => {
  const { t } = useTranslation('fields');
  const {
    data: { created, userEmail },
  } = usePaymentSessionForm();

  return (
    <VStack
      w="full"
      justifyContent="flex-start"
      alignItems="flex-start"
      divider={<Divider />}
      mt={3}
    >
      <DetailsItem label={t('email')} value={userEmail} />
      <DetailsItem
        label={t('created')}
        value={dayjs(created).format('DD.MM.YYYY HH:mm')}
      />
    </VStack>
  );
};
