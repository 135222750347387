import React, { PropsWithChildren, PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import createLogger from 'debug';
import { FullPageErrorScreen } from '../screens/full-page-error-screen';
import { CryptoPaymentNetworkError } from '@payler/payment-page-api-crypto';

interface IErrorBoundaryProps extends PropsWithChildren, WithTranslation {}

type TErrorBoundaryState = {
  hasError: boolean;
  error: CryptoPaymentNetworkError | Error | null;
};

const log = createLogger('ErrorBoundary');
class ErrorBoundaryComponent extends PureComponent<
  IErrorBoundaryProps,
  TErrorBoundaryState
> {
  static getDerivedStateFromError(error: unknown) {
    return { hasError: true, error };
  }

  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  override componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    log('Error handled %o', error);
  }

  public override render() {
    const { error, hasError } = this.state;
    if (hasError) {
      if (error instanceof CryptoPaymentNetworkError) {
        return (
          <FullPageErrorScreen
            status={error.response?.status}
            type={error.type}
          />
        );
      }
      return <FullPageErrorScreen />;
    }

    return this.props.children;
  }
}

export const ErrorBoundary = withTranslation()(ErrorBoundaryComponent);
