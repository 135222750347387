import {
  DividedFooter,
  InputClipboard,
  ScreenBody,
  ScreenHeader,
  SuccessIcon,
  DeclineIcon,
  useLayout,
  CopyTextBlock,
} from '@payler/payment-page-ui-shared';
import { ScreenWrapper } from '../components/wrappers/screen-wrapper';
import { Box, Button, Text, VStack } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
import { TextStyles } from '@payler/ui-theme';
import { Trans, useTranslation } from 'react-i18next';
import { useTransactionData } from '../hooks/transaction';
import { Alert, ThinDivider } from '@payler/ui-components';
import dayjs from 'dayjs';
import {
  usePaymentSessionForm,
  usePaymentSessionStatus,
  useSiteUrl,
} from '../hooks/payment-session';
import { LinkClipboard } from '../components/link-clipboard';
import { useCountdown } from '@payler/payment-page-ui-shared';
import { FC } from 'react';

export const PaymentResultScreen = () => {
  return (
    <ScreenWrapper>
      <Head />
      <ThinDivider borderColor="primary.25" />
      <Content />
      <Footer />
    </ScreenWrapper>
  );
};

const Head = () => {
  const { t } = useTranslation('common');
  const status = usePaymentSessionStatus();
  const isSuccess = status === 'completed';
  const title = isSuccess ? t('orderPaid') : t('paymentDeclined');
  const url = useSiteUrl();

  const timer = useCountdown(15, () => {
    if (url && process.env.NODE_ENV === 'production') {
      window.location.replace(url);
    }
  });

  return (
    <ScreenHeader>
      <VStack w="full" alignItems="start" spacing={0}>
        <Icon
          as={isSuccess ? SuccessIcon : DeclineIcon}
          color={isSuccess ? 'green.500' : 'red.500'}
          w={4}
          h={4}
        />
        <Text as="h1" textStyle={TextStyles.h1}>
          {title}
        </Text>
        {url && (
          <Text
            as="span"
            textStyle={TextStyles.BodyText16Medium}
            color="primary.350"
          >
            <Trans
              i18nKey="common:willBeRedirected"
              values={{ timer }}
              components={{
                darker: (
                  <Text
                    as="span"
                    color="primary.500"
                    textStyle={TextStyles.BodyText16Medium}
                  />
                ),
              }}
            />
          </Text>
        )}
      </VStack>
    </ScreenHeader>
  );
};

const Content = () => {
  const { t } = useTranslation('fields');
  const status = usePaymentSessionStatus();

  const {
    data: { paymentDescription, errorReason },
  } = usePaymentSessionForm();
  const {
    created,
    tokenCode,
    userEmail,
    amount,
    transactionId,
    transactionHash,
    transactionCheckUrl,
  } = useTransactionData();
  const isFailed = status === 'failed';

  return (
    <ScreenBody my={3}>
      <VStack w="full" spacing={3}>
        {isFailed && errorReason && (
          <ErrorReasonAlert errorReasonText={errorReason} />
        )}
        <VStack
          spacing={0}
          divider={<ThinDivider borderColor="primary.25" />}
          w="full"
          border="1px solid"
          borderRadius={1}
          borderColor="primary.100"
        >
          <CopyTextBlock
            label={t('created')}
            value={dayjs(created).format('DD.MM.YYYY, HH:mm:ss')}
            canCopy={false}
          />
          <CopyTextBlock
            label={t('receivedAmount')}
            value={`${amount} ${tokenCode}`}
            canCopy={false}
          />
          <CopyTextBlock
            label={t('paymentId')}
            value={transactionId || '-'}
            valueProps={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          />
          <LinkClipboard
            label={t('transactionHash')}
            value={transactionHash || '-'}
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            minH={78}
            boxShadow="none"
            fontSize={16}
            href={transactionCheckUrl || ''}
            canCopy
          />
          <CopyTextBlock
            label={t('email')}
            value={userEmail || ''}
            canCopy={false}
          />
          {paymentDescription && (
            <CopyTextBlock
              value={paymentDescription}
              label={t('paymentDescription')}
            />
          )}
        </VStack>
      </VStack>
    </ScreenBody>
  );
};

const Footer = () => {
  const { t } = useTranslation();
  const { isMobile } = useLayout();
  const url = useSiteUrl();

  if (!url) {
    return null;
  }

  const handleClickBackToSite = () => {
    window.location.replace(url);
  };

  if (isMobile) {
    return (
      <Box pt={2} pb={3} position="sticky" bottom={0} bg="secondary.500">
        <DividedFooter>
          <Button w="full" onClick={handleClickBackToSite}>
            {t('buttons:backToSite')}
          </Button>
        </DividedFooter>
      </Box>
    );
  }

  return (
    <Box pt={2} pb={4} px={4}>
      <VStack w="full">
        <Button w="full" onClick={handleClickBackToSite}>
          {t('buttons:backToSite')}
        </Button>
      </VStack>
    </Box>
  );
};

const ErrorReasonAlert: FC<{ errorReasonText: string }> = ({
  errorReasonText,
}) => {
  const { t } = useTranslation('common');
  return (
    <Alert
      status="error"
      label={t('reason').toUpperCase()}
      description={errorReasonText}
    />
  );
};
