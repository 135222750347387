import {
  BTCIcon,
  ETHIcon,
  LightningIcon,
  TRXIcon,
  USDCIcon,
  USDTIcon,
} from '@payler/payment-page-ui-shared';
import { FC } from 'react';
import { TCurrency } from '@payler/payment-page-api-crypto';

type TCryptoCurrencyIconProps = {
  currency: TCurrency;
};
export const CryptoCurrencyIcon: FC<TCryptoCurrencyIconProps> = ({
  currency,
}) => {
  switch (currency) {
    case 'BTC':
      return <BTCIcon />;
    case 'ETH':
      return <ETHIcon />;
    case 'TRX':
      return <TRXIcon />;
    case 'USDC':
      return <USDCIcon />;
    case 'USDT':
      return <USDTIcon />;
    default:
      return <LightningIcon />;
  }
};
