import { FC } from 'react';
import {
  FormLabel,
  InputGroup,
  InputRightElement,
  InputRightElementProps,
  Link,
  LinkProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { CopyComponent } from '@payler/payment-page-ui-shared';

/**
 * Этот компонент должен визуально быть похожем на InputClipboard,
 * кроме цвета и иметь возможность перехода по ссылке. Собственно поэтому тут используется Link из chakra-ui
 * */
export const LinkClipboard: FC<
  LinkProps & {
    label?: string;
    value: string;
    canCopy?: boolean;
    copyIconProps?: InputRightElementProps;
    labelProps?: TextProps;
  }
> = ({ label, value, labelProps, copyIconProps, canCopy, ...rest }) => {
  return (
    <InputGroup bg="primary.25">
      <Link
        href={rest.href || value}
        isExternal
        minH={78}
        fontSize={18}
        fontWeight={500}
        borderRadius={0}
        lineHeight="24px"
        pt="36px"
        pl={2}
        textStyle={TextStyles.BodyText16Medium}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        w={canCopy ? '85%' : 'full'}
        {...rest}
      >
        {value}
      </Link>
      {label && (
        <FormLabel variant="floating">
          <Text
            textStyle={TextStyles.Subtitle14Regular}
            color="primary.350"
            lineHeight="22px"
            {...labelProps}
          >
            {label}
          </Text>
        </FormLabel>
      )}
      {canCopy && (
        <InputRightElement h="full" {...copyIconProps}>
          <CopyComponent value={rest.href || value} />
        </InputRightElement>
      )}
    </InputGroup>
  );
};
