import { useTranslation } from 'react-i18next';
import { OperationProcessing, useLayout } from '@payler/payment-page-ui-shared';

export const PaymentProcessingScreen = () => {
  const { t } = useTranslation();
  const { isDesktop } = useLayout();

  return (
    <OperationProcessing
      title={t('common:paymentProcessing')}
      subtitle={
        isDesktop
          ? t('common:paymentInProcessOfBeingProcessed')
          : t('paymentInProcessOfBeingProcessedPleaseWait')
      }
      message={isDesktop ? t('common:pleaseWait') : undefined}
    />
  );
};
