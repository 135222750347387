import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { usePaymentSessionQuery } from '../hooks/payment-session';
import { FullPageLoader, NotFound } from '@payler/payment-page-ui-shared';

export const CryptoPaymentScreen: FC = () => {
  const { isLoading, data } = usePaymentSessionQuery();

  if (isLoading && !data) {
    return <FullPageLoader />;
  }

  if (!data) {
    return <NotFound />;
  }

  return <Outlet />;
};
