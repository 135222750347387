import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import createLogger from 'debug';
import {
  TCurrency,
  TDepositInfoRequestParams,
  TNetworkType,
} from '@payler/payment-page-api-crypto';
import { useInvalidatePaymentSessionQuery } from '../hooks/payment-session';
import { useCreateGlobalTimerRef } from '@payler/payment-page-ui-shared';
import {
  usePaymentSessionPresetData,
  usePaymentSessionStatus,
} from '../hooks/payment-session';
import { PENDING_AWAIT_TIME_MS } from '../consts';
import { useAvailableTokens } from '../hooks/tokens';
import { MayBe } from '@payler/utils';

const log = createLogger('CryptoPaymentContextProvider');

type TScreen =
  | 'PaymentSelectionScreen'
  | 'DepositInfoScreen'
  | 'ProcessingTransactionScreen'
  | 'PaymentResultScreen'
  | 'PaymentProcessingScreen';
type TSelectedToken = TDepositInfoRequestParams;

type TCryptoPaymentContext = {
  selectedToken: TSelectedToken | undefined;
  setSelectedToken: (selectedToken: TSelectedToken) => void;
  screen: TScreen | undefined;
  setScreen(screen: TScreen): void;
};

const CryptoPaymentContext = createContext<TCryptoPaymentContext>(
  {} as unknown as TCryptoPaymentContext,
);

export const CryptoPaymentContextProvider: FCC = ({ children }) => {
  const [screen, setScreen] = useState<TScreen>();
  const [selectedToken, setSelectedToken] = useState<
    TSelectedToken | undefined
  >();
  const timerRef = useCreateGlobalTimerRef('transactionTimer');
  const timeoutRef = useCreateGlobalTimerRef('transactionTimeout');
  const { tokenCode, networkType } = usePaymentSessionPresetData();
  const paymentSessionStatus = usePaymentSessionStatus();
  const invalidateSessionQuery = useInvalidatePaymentSessionQuery();
  const availableTokens = useAvailableTokens();

  const handleSetScreen = (screen: TScreen) => {
    log('set screen %s', screen);
    setScreen(screen);
  };
  const clearTimers = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, [timeoutRef, timerRef]);

  const startRefetchingProcess = useCallback(
    (delay = PENDING_AWAIT_TIME_MS) => {
      if (timerRef.current) {
        return;
      }

      timerRef.current = setInterval(invalidateSessionQuery, 2_000);

      if (!timeoutRef.current) {
        timeoutRef.current = setTimeout(() => {
          if (timerRef.current) {
            clearInterval(timerRef.current);
          }
          timeoutRef.current = null;
        }, delay);
      }
    },
    [invalidateSessionQuery, timeoutRef, timerRef],
  );

  const showDepositInfoScreen = useCallback(
    (network: MayBe<TNetworkType>, currency: MayBe<TCurrency>) => {
      if (currency && network) {
        handleSetScreen('DepositInfoScreen');
        setSelectedToken({ TokenCode: currency, NetworkType: network });
      } else {
        throw new Error('Network type and token code is required');
      }
    },
    [],
  );

  useEffect(() => {
    if (
      paymentSessionStatus !== 'completed' &&
      paymentSessionStatus !== 'failed'
    ) {
      return;
    }

    handleSetScreen('PaymentResultScreen');
  }, [paymentSessionStatus]);

  useEffect(() => {
    if (!['pending', 'markedPaid'].includes(paymentSessionStatus || '')) {
      clearTimers();
    }
  }, [clearTimers, paymentSessionStatus]);

  useEffect(() => {
    if (
      paymentSessionStatus !== 'created' ||
      !!screen ||
      availableTokens?.isLoading
    ) {
      return;
    }

    if (availableTokens?.data?.length === 1) {
      const { networkType, symbol } = availableTokens.data[0] || {};
      showDepositInfoScreen(networkType, symbol);
    } else if (networkType && tokenCode) {
      showDepositInfoScreen(networkType, tokenCode);
    } else {
      handleSetScreen('PaymentSelectionScreen');
    }
  }, [
    availableTokens.data,
    networkType,
    paymentSessionStatus,
    screen,
    showDepositInfoScreen,
    tokenCode,
  ]);

  useEffect(() => {
    if (paymentSessionStatus !== 'pending') {
      return;
    }

    if (screen !== 'ProcessingTransactionScreen') {
      handleSetScreen('ProcessingTransactionScreen');
      startRefetchingProcess();
    }
  }, [
    clearTimers,
    paymentSessionStatus,
    screen,
    startRefetchingProcess,
    timerRef,
  ]);

  useEffect(() => {
    if (paymentSessionStatus !== 'markedPaid') {
      return;
    }
    if (screen !== 'PaymentProcessingScreen') {
      handleSetScreen('PaymentProcessingScreen');
      startRefetchingProcess(600_000);
    }
  }, [clearTimers, paymentSessionStatus, screen, startRefetchingProcess]);

  const handleSelectToken = useCallback((token: TSelectedToken) => {
    log('set token %o', token);
    if (token) {
      handleSetScreen('DepositInfoScreen');
    }

    setSelectedToken(token);
  }, []);

  const ctx = useMemo<TCryptoPaymentContext>(
    () => ({
      screen,
      setScreen,
      selectedToken,
      setSelectedToken: handleSelectToken,
    }),
    [handleSelectToken, screen, selectedToken],
  );

  return (
    <CryptoPaymentContext.Provider value={ctx}>
      {children}
    </CryptoPaymentContext.Provider>
  );
};

export const useCryptoPaymentContext = () => useContext(CryptoPaymentContext);
