import { FullScreenError } from '@payler/payment-page-ui-shared';
import { CryptoPaymentNetworkError } from '@payler/payment-page-api-crypto';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type TFullPageErrorScreenProps = {
  status?: number;
  type?: CryptoPaymentNetworkError['type'];
};

export const FullPageErrorScreen: FC<TFullPageErrorScreenProps> = ({
  status,
  type,
}) => {
  const { t } = useTranslation('errorPage');
  const errorTitle = useErrorTitle(type);

  return (
    <FullScreenError
      title={status || t('oops')}
      errorTitle={errorTitle}
      reloadPageText={t('reloadPage')}
    />
  );
};

const useErrorTitle = (type?: CryptoPaymentNetworkError['type']) => {
  const { t } = useTranslation('errorPage');

  switch (type) {
    case 'expired':
      return t('timeExpired');
    case 'other':
      return t('unspecifiedError');
    default:
      return t('somethingWentWrong');
  }
};
