import {
  DesktopWrapper,
  MobileWrapper,
  useLayout,
} from '@payler/payment-page-ui-shared';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo } from 'react';

export const ScreenWrapper: FCC<{ onBack?: () => void }> = ({
  onBack,
  children,
}) => {
  const { isDesktop } = useLayout();
  const { t } = useTranslation('buttons');

  const backButtonProps = useMemo(
    () => (onBack ? { text: t('back'), onClick: onBack } : undefined),
    [onBack, t],
  );

  return isDesktop ? (
    <DesktopWrapper>{children}</DesktopWrapper>
  ) : (
    <MobileWrapper backButtonProps={backButtonProps}>{children}</MobileWrapper>
  );
};
