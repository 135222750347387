import { ChakraProvider } from '@chakra-ui/react';
import { GlobalFonts } from '@payler/ui-theme';
import { theme } from '@payler/payment-page-ui-shared';

export const ChakraWrapper: FCC = ({ children }) => {
  return (
    <ChakraProvider theme={theme}>
      <GlobalFonts />
      {children}
    </ChakraProvider>
  );
};
