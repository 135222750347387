import { CurrencySelectionScreen } from './currency-selection-screen';
import { useCryptoPaymentContext } from '../context/crypto-payment-context';
import { DepositInfoScreen } from './deposit-info-screen';
import { ProcessingTransactionScreen } from './processing-transaction-screen';
import { PaymentResultScreen } from './payment-result-screen';
import { PaymentProcessingScreen } from './payment-processing-screen';

export const MainScreen = () => {
  const { screen } = useCryptoPaymentContext();
  switch (screen) {
    case 'PaymentSelectionScreen':
      return <CurrencySelectionScreen />;
    case 'DepositInfoScreen':
      return <DepositInfoScreen />;
    case 'ProcessingTransactionScreen':
      return <ProcessingTransactionScreen />;
    case 'PaymentResultScreen':
      return <PaymentResultScreen />;
    case 'PaymentProcessingScreen':
      return <PaymentProcessingScreen />;
    default:
      return null;
  }
};
