import { PaymentPageApiCrypto } from '@payler/payment-page-api-crypto';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

export type TCryptoPaymentEnv = {
  baseUrl: string;
};

type TCryptoPaymentPageConfigContext = {
  api: PaymentPageApiCrypto;
};

const CryptoPaymentPageConfigContext =
  createContext<TCryptoPaymentPageConfigContext>({
    api: null as unknown as PaymentPageApiCrypto,
  });

export const CryptoPaymentPageConfigContextProvider: FCC<{
  env: TCryptoPaymentEnv;
}> = ({ children, env }) => {
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    if (error instanceof Error) {
      throw error;
    }
  }, [error]);

  const ctx = useMemo(
    () => ({ api: new PaymentPageApiCrypto(env.baseUrl, setError) }),
    [env.baseUrl]
  );

  return (
    <CryptoPaymentPageConfigContext.Provider value={ctx}>
      {children}
    </CryptoPaymentPageConfigContext.Provider>
  );
};

export const useApi = () => useContext(CryptoPaymentPageConfigContext).api;
