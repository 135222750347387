import { useTranslation } from 'react-i18next';
import {
  Box,
  Center,
  Heading,
  VStack,
  Text,
  Flex,
  Circle,
  HStack,
  Icon,
} from '@chakra-ui/react';
import { ScreenWrapper } from '../components/wrappers/screen-wrapper';
import {
  FullPageLoader,
  MethodBlockWrapper,
  NothingFoundIcon,
  RightIcon,
  ScreenBody,
  ScreenHeader,
} from '@payler/payment-page-ui-shared';
import { Input } from '@payler/ui-components';
import { Search2Icon } from '@chakra-ui/icons';
import React, { FC, isValidElement } from 'react';
import { TextStyles } from '@payler/ui-theme';
import { useAvailableTokens, useSearchTokens } from '../hooks/tokens';
import {
  TCurrency,
  TToken,
  TTokenStandard,
} from '@payler/payment-page-api-crypto';
import { CryptoCurrencyIcon } from '../components/crypto-currency-icon';
import { useCryptoPaymentContext } from '../context/crypto-payment-context';

export const CurrencySelectionScreen = () => {
  const { isLoading, data } = useAvailableTokens();

  if (isLoading && !data) {
    return <FullPageLoader />;
  }

  return (
    <ScreenWrapper>
      <Head />
      <Content />
    </ScreenWrapper>
  );
};

const Head = () => {
  const { t } = useTranslation();
  return (
    <ScreenHeader>
      <Heading>{t('common:selectCurrency')}</Heading>
    </ScreenHeader>
  );
};

const Content = () => {
  const { searchValue, onSearch, searchedData } = useSearchTokens();

  return (
    <ScreenBody mb={4}>
      <VStack w="full" spacing={2}>
        <SearchField searchValue={searchValue} onSearch={onSearch} />
        <CurrencyList data={searchedData} />
      </VStack>
    </ScreenBody>
  );
};

const SearchField: FC<{
  searchValue: string;
  onSearch: (searchValue: string) => void;
}> = ({ searchValue, onSearch }) => {
  const { t } = useTranslation();

  return (
    <Input
      leftElementProps={{
        children: <Search2Icon w="20px" h="20px" color="primary.400" />,
        pointerEvents: 'none',
        paddingLeft: 2,
      }}
      placeholder={t('placeholders:search')}
      value={searchValue}
      onChange={(event) => onSearch(event.target.value)}
    />
  );
};

const CurrencyList: FC<{ data?: TToken[] }> = ({ data }) => {
  const { setSelectedToken } = useCryptoPaymentContext();
  return (
    <MethodBlockWrapper>
      {data?.length &&
        data.map((token) => (
          <CurrencyButton
            key={token.name}
            dataTestId={token.name}
            title={
              <PaymentMethodTitle
                symbol={token.symbol}
                network={token.networkDisplayName}
                name={token.name}
              />
            }
            icon={
              <CryptoPaymentMethodIcon
                currency={token.symbol}
                standard={token.standard}
              />
            }
            onClick={() => {
              setSelectedToken({
                TokenCode: token.symbol,
                NetworkType: token.networkType,
              });
            }}
          />
        ))}
      {!data?.length && <NotFoundCurrencies />}
    </MethodBlockWrapper>
  );
};

const NotFoundCurrencies = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Center minH="432px">
        <VStack w="full" spacing={3}>
          <NothingFoundIcon />
          <Text textStyle={TextStyles.BodyText16Semibold} color="primary.350">
            {t('common:nothingFound')}
          </Text>
        </VStack>
      </Center>
    </Box>
  );
};

const PaymentMethodTitle: FC<{
  symbol: string;
  network: string;
  name?: string;
}> = ({ symbol, name, network }) => {
  return (
    <VStack w="full" gap={0}>
      <Text
        w="full"
        textAlign="start"
        textStyle={TextStyles.BodyText16Medium}
        whiteSpace="nowrap"
        maxWidth="full"
        overflow="hidden"
        textOverflow="ellipsis"
        alignItems="center"
      >
        {symbol}
      </Text>
      <Text
        w="full"
        textStyle={TextStyles.Caption12Regular}
        color="primary.350"
        textAlign="start"
      >
        {name && <>{name}&nbsp;&#183;&nbsp;</>}
        {network}
      </Text>
    </VStack>
  );
};

const CryptoPaymentMethodIcon: FC<{
  currency: TCurrency;
  standard?: TTokenStandard;
}> = ({ standard, currency }) => {
  return (
    <>
      <CryptoCurrencyIcon currency={currency} />
      {standard && (
        <TokenStandardTag>{standard.toUpperCase()}</TokenStandardTag>
      )}
    </>
  );
};

const TokenStandardTag: FCC = ({ children }) => (
  <Box
    borderRadius="40px"
    border="1px solid"
    borderColor="white.500"
    bg="black"
    textColor="white.500"
    position="absolute"
    bottom="-6px"
    px="2px"
  >
    <Text fontSize={8}>{children}</Text>
  </Box>
);

//INFO: Компонент из payment-page-shared не подходит по причине того, что там есть проблема с элементами с абсолютным позиционированием
export const CurrencyButton: FC<{
  title: React.ReactNode;
  icon: React.ReactElement;
  onClick: () => void;
  dataTestId?: string;
}> = ({ title, icon, onClick, dataTestId }) => {
  return (
    <HStack
      w="full"
      px={3}
      py={2}
      spacing={3}
      onClick={onClick}
      cursor="pointer"
      data-testid={dataTestId ?? 'crypto-payment-method-wrapper'}
    >
      <HStack w="full" p={0} spacing={2}>
        <Circle
          size={5}
          bg="primary.25"
          border="1px solid"
          borderColor="black.100"
          color="primary.300"
          position="relative"
        >
          {icon}
        </Circle>
        {isValidElement(title) ? (
          title
        ) : (
          <Text
            as={Flex}
            textStyle={TextStyles.BodyText16Medium}
            whiteSpace="nowrap"
            maxWidth="full"
            overflow="hidden"
            textOverflow="ellipsis"
            alignItems="center"
          >
            {title}
          </Text>
        )}
      </HStack>
      <Icon as={RightIcon} w={2.5} h={2.5} color="primary.300" />
    </HStack>
  );
};
